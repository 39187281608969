import { useEffect, useState } from 'react'
import styles from './Tags.module.css'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import ViewTag from '../ViewTag/ViewTag';
import Vasts from '../Vasts/Vasts';
import CallbacksTable from '../CallbacksTable/CallbacksTable'
import { useNavigate } from "react-router-dom";
import LinearProgress from '@mui/material/LinearProgress';
import TextField from "@mui/material/TextField";
import DeleteTag from '../DeleteTag/DeleteTag';
import UpdateTag from '../UpdateTag/UpdateTag';
import EventsTable from '../EventsTable/EventsTable';
import { FaImage, FaLink } from 'react-icons/fa';

const Tags = () => {
    const navigate = useNavigate();
    const [tags, setTags] = useState([]);
    const [open, setOpen] = useState(false);
    const [id, setId] = useState(0);
    const [name, setName] = useState('');
    const [domain, setDomain] = useState();
    const [showTag, setShowTag] = useState(false);
    const [showVast, setShowVast] = useState(false);
    const [showEvent, setShowEvent] = useState(false);
    const [showCallback, setShowCallback] = useState(false);
    const [loading, setLoading] = useState(true);
    const [capacityFilter, setCapacityFilter] = useState(false);
    const [dailyCapacityFilter, setDailyCapacityFilter] = useState(false);
    const [showDeletTag, setShowDeleteTag] = useState(false);
    const [showEditTag, setShowEditTag] = useState(false);
    const [paltforms, setPlatforms] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState(null);

    useEffect(() => {
        try {
            (async () => {
                const res = await fetch(`${process.env.REACT_APP_API_URL}/admin/tags`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': 'Bearer ' + localStorage.getItem('token')
                    },
                });
                if (res.status === 403) {
                    navigate("/");
                }
                const data = await res.json();
                if (data) {
                    setLoading(false);
                    setTags(data.tags);
                    setCapacityFilter(data.capacityFilter);
                    setDailyCapacityFilter(data.dailyCapacityFilter);
                    setPlatforms(data.platforms);
                }
            }
            )()
        } catch (error) {
            console.error(error);
        }
    }, [open])


    const viewTag = (e) => {
        setId(e.target.id);
        setName(e.target.name);
        setDomain(e.target.dataset.domain);
        setShowTag(true);
        setShowVast(false);
        setShowEvent(false);
        setShowCallback(false);
        setShowEditTag(false);
        setShowDeleteTag(false)
        setOpen(true);
    };

    const getVasts = (e) => {
        setId(e.target.id);
        setShowVast(true);
        setShowTag(false);
        setShowEvent(false);
        setShowCallback(false);
        setShowEditTag(false);
        setShowDeleteTag(false)
        setOpen(true);

    }
    const addEvent = (e) => {
        setId(e.target.id);
        setShowEvent(true);
        setShowTag(false);
        setShowVast(false);
        setShowCallback(false);
        setShowEditTag(false);
        setShowDeleteTag(false)
        setOpen(true);

    }

    const addCallback = (e) => {
        setId(e.target.id);
        setShowTag(false);
        setShowVast(false);
        setShowEvent(false);
        setShowCallback(true);
        setShowEditTag(false);
        setShowDeleteTag(false)
        setOpen(true);
    }


    const handleClose = () => {
        setOpen(false);
        setShowTag(false);
        setShowVast(false);
        setShowEvent(false);
        setShowCallback(false);
        setShowDeleteTag(false);
    };


    const updateQuota = async (id) => {
        try {
            const { quota, daily_capacity } = tags.find(tag => tag.id === id)
            const res = await fetch(`${process.env.REACT_APP_API_URL}/admin/tag/${id}`, {
                mode: 'cors',
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                },
                body: JSON.stringify({ quota, daily_capacity })
            });

            const data = await res.json();

            setTags(tags.map(tag => tag.id === data.id ? { ...tag, data } : tag));
        } catch (error) {
            console.error(error);
        }
    }

    const onKeyPress = (e, id, key) => {
        if (e.charCode === 13) {
            return updateQuota(id, { [key]: e.target.value });
        }
    }

    const updateTag = (id, update) => {
        setTags(tags.map(tag => {
            return tag.id === id ? Object.assign(tag, update) : tag;
        }))
    }

    const editTag = (e) => {
        setId(e.target.id);
        setShowEditTag(true);
        setShowTag(false);
        setShowVast(false);
        setShowEvent(false);
        setShowCallback(false);
        setShowDeleteTag(false)
        setOpen(true);
    }

    const deleteTag = (e) => {
        setId(e.target.id)
        setShowDeleteTag(true)
        setShowTag(false);
        setShowVast(false);
        setShowEvent(false);
        setShowCallback(false);
        setShowEditTag(false);
        setOpen(true);
    }

    const handleOpenDialog = (content) => {
        setDialogContent(content);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setDialogContent(null);
    };


    return (
        <div>
            {loading ? <LinearProgress /> :
                <table className={styles.table}>
                    <thead className={styles.tableRowHeader}>
                        <tr>
                            <th className={styles.tableRowHeader1}>Tag ID</th>
                            <th>Advertiser ID</th>
                            <th>Publisher Name</th>
                            {capacityFilter && <th className="capacity-column">Traffic Running</th>}
                            {capacityFilter && <th className="capacity-column">Hourly Quota</th>}
                            {dailyCapacityFilter && <th className="capacity-column">Daily Quota</th>}
                            <th>Description</th>
                            <th>Width</th>
                            <th>Height</th>
                            <th>Speed</th>
                            <th>Domain</th>
                            <th>Photo</th>
                            <th>Link</th>
                            <th colSpan="6" style={{ borderTopRightRadius: "19px" }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {tags.map((tag) => {
                            return (<tr key={tag.id}>
                                <td>{tag.id}</td>
                                <td>{tag.publisher_id}</td>
                                <td>{tag.name}</td>
                                {capacityFilter && <td>{tag.blocked || tag.daily_blocked ? 'no' : 'yes'}</td>}
                                {capacityFilter &&
                                    <td>
                                        <TextField type='number' onBlur={(e) => updateQuota(tag.id)}
                                            id={`${tag.id}-quota-update`}
                                            label="Quota"
                                            variant="outlined"
                                            value={tag.quota}
                                            onChange={e => updateTag(tag.id, { quota: e.target.value })}
                                            onKeyPress={e => onKeyPress(e, tag.id, 'quota')}
                                        />
                                    </td>
                                }
                                {dailyCapacityFilter &&
                                    <td>
                                        <TextField type='number' onBlur={(e) => updateQuota(tag.id)}
                                            id={`${tag.id}-daily-quota-update`}
                                            label="Daily Quota"
                                            variant="outlined"
                                            value={tag.daily_capacity}
                                            onChange={e => updateTag(tag.id, { daily_capacity: e.target.value })}
                                            onKeyPress={e => onKeyPress(e, tag.id, 'daily_capacity')}
                                        />
                                    </td>
                                }
                                <td>{tag.description}</td>
                                <td>{tag.width}</td>
                                <td>{tag.height}</td>
                                <td>{tag.speed}</td>
                                <td>{tag.domain}</td>
                                <td>
                                    <FaImage
                                        size={20}
                                        color="blue"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() =>
                                            handleOpenDialog(
                                                <div style={{ textAlign: 'center' }}>
                                                    <img src={tag.photo} alt="Photo" style={{ maxWidth: '100%' }} />
                                                    <p style={{ marginTop: '10px' }}>
                                                        <a href={tag.photo} target="_blank" rel="noopener noreferrer">
                                                            {tag.photo}
                                                        </a>
                                                    </p>
                                                </div>
                                            )
                                        }
                                    />
                                </td>
                                <td>
                                    <FaLink
                                        size={20}
                                        color="blue"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() =>
                                            handleOpenDialog(
                                                <a href={tag.link} target="_blank" rel="noopener noreferrer">
                                                    {tag.link}
                                                </a>
                                            )
                                        }
                                    />
                                </td>
                                <td><button className={styles.bu} id={tag.id} name={tag.name} data-domain={tag.domain} variant="outlined" onClick={viewTag}>Show Script</button></td>
                                <td><button className={styles.bu} id={tag.id} name={tag.name} variant="outlined" onClick={editTag}>Edit Tag</button></td>
                                <td><button className={styles.deleteButton} id={tag.id} name={tag.name} variant="outlined" onClick={deleteTag}>Delete Tag</button></td>
                                <td><button id={tag.id} onClick={getVasts}>Vasts</button></td>
                                <td><button id={tag.id} onClick={addEvent}>+ Add Event</button></td>
                                <td><button name={tag.name} id={tag.id} onClick={addCallback} className={styles.addVast}>Add Callback</button></td>
                            </tr>
                            )
                        })}
                    </tbody>
                    <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
                        <DialogTitle>Details</DialogTitle>
                        <DialogContent>{dialogContent}</DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDialog} color="primary">
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                </table>
            }
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                BackdropProps={{ style: { background: "rgba(0, 0, 0, 0.15)" } }}
            >
                <DialogTitle id="alert-dialog-title"></DialogTitle>
                <DialogContent>
                    {showTag &&
                        <ViewTag id={id}
                            name={name}
                            domain={domain}
                            onClose={handleClose}
                        />
                    }
                    {showVast &&
                        <Vasts
                            id={id}
                            onClose={handleClose}
                        />
                    }
                    {showEvent &&
                        <EventsTable
                            id={id}
                            onClose={handleClose}
                        />
                    }
                    {showCallback &&
                        <CallbacksTable
                            id={id}
                            name={name}
                            onClose={handleClose}
                        />
                    }
                    {showDeletTag &&
                        <DeleteTag
                            id={id}
                            onClose={handleClose}
                        />
                    }
                    {showEditTag &&
                        <UpdateTag
                            id={id}
                            platforms={paltforms}
                            onClose={handleClose}
                        />
                    }
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default Tags;