import styles from './UpdateVast.module.css'
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CloseIcon from '@mui/icons-material/Close';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

const UpdateVast = (props) => {
    const [url, setUrl] = useState('');
    const [name, setName] = useState('');
    const [maxRetry, setMaxRetry] = useState('');
    const [priority, setPriority] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            try {
                const res = await fetch(`${process.env.REACT_APP_API_URL}/admin/get/vast/${props.id}`, {
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': 'Bearer ' + localStorage.getItem('token')
                    },
                });
                if (res.status === 403) {
                    navigate("/");
                }
                const data = await res.json();
                setName(data.tag_name);
                setUrl(data.vast_url);
                setMaxRetry(data.max_retry);
                setPriority(data.priority);
            } catch (err) {
                console.error(err);
            }
        }
        )()


    }, [])

    const save = async (e) => {

        const vast = { id: props.id, url: url, TagName: name, maxRetry: maxRetry, priority: priority};

        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/admin/update/vast`, {
                method: 'Put',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                },
                body: JSON.stringify(vast)
            });
            if (res.status === 403) {
                navigate("/");
            }
            const data = await res.json();
            props.onClose();

        } catch (err) {
            console.log(err);
        }
    }

    const backToLayOut = () => {
        props.onClose();
    }



    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
            >
                <Card sx={{ width: 500, height: 500, borderRadius: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                    <CardHeader
                        action={
                            <IconButton sx={{ position: 'relative', left: '200%' }} onClick={backToLayOut}>
                                <CloseIcon />
                            </IconButton>
                        }
                        title="Update Vast"
                        sx={{ color: '#289FD2' }}
                    />
                    <CardContent sx={{ width: "inherit" }} >
                        <Box
                            sx={{
                                '& > :not(style)': { m: 1, width: '50ch' },

                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <TextField value={url} onChange={(e) => { setUrl(e.target.value); }} type='text' id="outlined-basic" label="Url" variant="outlined" />
                            <TextField value={name} onChange={(e) => { setName(e.target.value); }} type='text' id="outlined-basic" label="Name" variant="outlined" />
                            <TextField value={priority} onChange={(e) => { setPriority(e.target.value); }} type='number' id="outlined-basic" label="Priority" variant="outlined" />
                            <TextField value={maxRetry} onChange={(e) => { setMaxRetry(e.target.value); }} type='number' id="outlined-basic" label="Max Retry" variant="outlined" />
                        </Box>
                    </CardContent>
                    <CardActions sx={{ display: 'flex', alignItems: 'center' }}>
                        <button className={styles.submitButton} onClick={save}>save</button>
                    </CardActions>
                </Card>
            </Backdrop>
        </div>
    )

}

export default UpdateVast